import { getSearchList, getPageProdByCategory, getProdListByTagId } from '@/api'

export default {
  namespaced: true,
  state: {
    searchList: {
      attrsList: [],
      goodsList: [],
      trademarkList: [],
    },
  },
  mutations: {
    SEARCHLIST(state, searchList) {
      state.searchList = searchList
    },
    PAGEPRODBYCATEGORY(state, searchList) {
      state.searchList = searchList
    },
  },
  actions: {
    async getSearchList({ commit }, params = {}) {
      const res = await getSearchList(params)
      if (res.code === 0) {
        commit('SEARCHLIST', res.data)
      }
    },
    async getPageProdByCategory({ commit }, params) {
      const res = await getPageProdByCategory(params)
      if (res.code === 0) {
        commit('SEARCHLIST', res.data)
        return 'ok'
      }
      return Promise.reject(new Error('fail'))
    },
    async getProdListByTagId({ commit }, params) {
      const res = await getProdListByTagId(params)
      if (res.code === '00000') {
        commit('SEARCHLIST', res.data)
        return 'ok'
      }
      return Promise.reject(new Error('fail'))
    },
  },
  getters: {
    attrsList(state) {
      return state.searchList.attrsList
    },
    goodsList(state) {
      return state.searchList.list
    },
    trademarkList(state) {
      return state.searchList.trademarkList
    },
    total(state) {
      return state.searchList.total
    },
  },
}
