import {
  getBannerList,
  getCategoryList,
  getTagList,
  getNoticeList,
  getNoticeInfo,
  getTagProdList,
} from '@/api'
import { handleTree } from '@/utils'

export default {
  namespaced: true,
  state: {
    // state 的默认初始值应该与接口中返回的值类型一致
    categoryList: [],
    bannerList: [],
    floorList: [],
    tagList: [],
    noticeList: [],
    noticeInfo: [],
    tagProdList: [],
    currentTag: -1,
  },
  mutations: {
    CATEGORYLIST(state, categoryList) {
      console.log('categoryList', categoryList)
      state.categoryList = categoryList
    },
    BANNERLIST(state, bannerList) {
      state.bannerList = bannerList
    },
    FLOORLIST(state, floorList) {
      state.floorList = floorList
    },
    TAGLIST(state, tagList) {
      state.tagList = tagList
    },
    NOTICELIST(state, noticeList) {
      state.noticeList = noticeList
    },
    NOTICEINFO(state, noticeInfo) {
      state.noticeInfo = noticeInfo
    },
    TAGPRODLIST(state, tagProdList) {
      state.tagProdList = tagProdList
    },
    CURRENTTAG(state, currentTag) {
      state.currentTag = currentTag
    },
  },
  actions: {
    async categoryList({ commit }) {
      const res = await getCategoryList()
      if (res.code === 0) {
        commit('CATEGORYLIST', handleTree(res.data))
      }
    },
    async getTagList({ commit }) {
      const res = await getTagList()
      if (res.code === '00000') {
        commit('TAGLIST', res.data)
      }
    },
    async getBannerList({ commit }) {
      const res = await getBannerList({
        position: '1',
      })
      if (res.code === 0) {
        commit('BANNERLIST', res.data)
      }
    },
    async getNoticeList({ commit }, params) {
      const res = await getNoticeList(params)
      if (res.code === '00000') {
        commit('NOTICELIST', res.data)
      }
    },
    async getNoticeInfo({ commit }, id) {
      const res = await getNoticeInfo(id)
      if (res.code === '00000') {
        commit('NOTICEINFO', res.data)
      }
    },
    async getTagProdList({ commit }) {
      const res = await getTagProdList()
      if (res.code === '00000') {
        commit('TAGPRODLIST', res.data)
      }
    },
  },
  getters: {},
}
