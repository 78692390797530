<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="i in list" :key="i.id">
        <!-- <el-image style="width: 750px; height: 330px" :src="i.imgUrl" fit="contain"></el-image> -->
        <img :src="i.picUrl" :data-relation="i.relation" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev"></div> -->
    <!-- <div class="swiper-button-next"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper"
import 'swiper/css/swiper.min.css'

export default {
  name: 'Carousel',
  props: ['list'],
  watch: {
    list: {
      immediate: true,
      handler () {
        this.$nextTick(() => {
          new Swiper('.swiper-container', {
            autoplay: true,
            loop: true,
            loopAdditionalSlides: 3,
            // navigation: {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev',
            // },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            on: {
              click: (swiper) => {
                const prodId = swiper.target.attributes['data-relation'].value
                this.$router.push({
                  name: 'detail',
                  params: {
                    prodId
                  }
                })
              },
              toedge: (swiper) => {
                this.$forceUpdate()
              }
            }
          })
        })
      }
    }
  },
  methods: {
    handleToDetail (prodId) {

    }
  }
}
</script>

<style lang="scss" scoped>
img {
  cursor: pointer;
  width: 720px;
  height: 330px;
  object-fit: cover;
  object-position: center center;
}
</style>