import {
  getCartCount,
  deleteCart,
  getCartList,
  getCheckCart,
  getTotalPay,
  postOrderConfirm,
  postCollect,
  updateCartSelected,
} from '@/api'
import { getToken, paramsToQuery } from '@/utils'

export default {
  namespaced: true,
  state: {
    cartList: [],
    selectedIds: [], // 已选列表
    isAllSelected: false, // 是否全选
    totalPriceSelected: 0, // 选中项总金额
    cartCount: 0,
    confirmData: {
      totalCount: 0,
    },
    confirmParams: {},
  },
  mutations: {
    CARTLIST(state, cartList) {
      state.cartList = cartList
      // 计算各种关联属性
      state.selectedIds = []
      state.isAllSelected = true
      state.totalPriceSelected = 0
      state.cartList.forEach((item) => {
        if (item.selected) {
          state.selectedIds.push(item.id)
          state.totalPriceSelected += item.count * item.sku.price
        } else {
          state.isAllSelected = false
        }
      })
    },
    CARTCOUNT(state, cartCount) {
      state.cartCount = cartCount
    },
    CARTTOAL(state, cartTotal) {
      state.cartTotal = cartTotal
    },
    CONFIRMDATA(state, confirmData) {
      state.confirmData = confirmData
    },
    UPDATEADDRESS(state, userAddr) {
      state.confirmData.userAddr = userAddr
    },
    UPDATEPAYTYPE(state, payType) {
      state.confirmData.payType = payType
    },
    CONFIRMPARAMS(state, confirmParams) {
      state.confirmParams = confirmParams
    },
  },
  actions: {
    async getCartCount({ commit }) {
      const token = getToken()
      if (token) {
        const res = await getCartCount()
        if (res.code === 0) {
          commit('CARTCOUNT', res.data)
        }
      } else {
        commit('CARTCOUNT', 0)
      }
    },
    async getCartList({ commit }) {
      const res = await getCartList()
      if (res.code === 0) {
        commit('CARTLIST', res.data.validList)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async getTotalPay({ commit }, arr) {
      const res = await getTotalPay(arr)
      if (res.code === '00000') {
        commit('CARTTOAL', res.data)
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async deleteCart(_, skuId) {
      const res = await deleteCart(skuId)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async updateCartSelected(_, params) {
      const res = await updateCartSelected(params)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    deleteCheckedItem({ getters, dispatch }) {
      const list = getters.cartInfoList
      let promiseAll = []
      list.map((item) => {
        const promise =
          item.isChecked === 1 && dispatch('deleteCart', item.skuId)
        promise && promiseAll.push(promise)
      })
      return Promise.all(promiseAll)
    },
    allCheckedOnchange({ getters, dispatch }, isAllChecked) {
      const list = getters.cartInfoList
      let promiseAll = []
      list.map((item) => {
        if (isAllChecked !== item.isChecked) {
          const promise = dispatch('getCheckCart', {
            skuId: item.skuId,
            isChecked: isAllChecked,
          })
          promiseAll.push(promise)
        }
      })
      return Promise.all(promiseAll)
    },
    // 确认订单
    async postOrderConfirm({ commit }, params) {
      console.log('params', params)
      const res = await postOrderConfirm(params)
      if (res.code === 0) {
        commit('CONFIRMDATA', res.data)
        commit('CONFIRMPARAMS', params)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {
    cartList(state) {
      return state.cartList ? state.cartList : []
    },
    cartCount(state) {
      return state.cartList.length || 0
    },
  },
}
